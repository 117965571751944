<template>
    <el-dialog title="请输入新的说明"
               :fullscreen="true"
               :visible.sync="dialogFormVisible_"
               @closed="hide()"
               :before-close="handleClose">
        <Editor ref="Editor" :editortext="inputForm.collection_explain" @modifyPlan="modifyPlan"></Editor>
    </el-dialog>
</template>

<script>
    import Editor from '../../../../../../components/editor/editor' // 查看弹窗
    export default {
        components: {Editor},
        data() {
            return {
                visible: false,
                loading: false,
                dialogFormVisible_: false,
                handleClose: false,
                inputForm: {}
            }
        },
        methods: {
            init(row) {
                this.inputForm = row
                this.visible = true
                this.dialogFormVisible_ = true
            },
            modifyPlan(html) {
                console.log("接收到:", html)
                this.$axios(this.api.dyyg.updateCollection2, {
                    id: this.inputForm.id,
                    collectionExplain: html,
                }, "post").then((res) => {
                    if (res.status) {
                        this.$message.success('操作成功')
                        this.dialogFormVisible_ = false
                    }
                })
            },
            // 对编辑器进行加载
            show() {

            },
            // 关闭时清空数据避免重叠
            hide() {
                this.inputForm.collection_explain = null
            },
        }
    }
</script>
